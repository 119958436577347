/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
// import "assets/scss/paper-kit.scss";
import "assets/css/paper-kit.min.css";
import "assets/demo/demo.css";

import Home from "views/home.js";
import RegisterPage from "views/Register.js";
import Sponsor from "views/Sponsor";
import Speaker from "views/SpeakerPage";
import Privacy from "views/Privacy";
import ThankYou from "./views/ThankYou";
import ThankYouRegister from "./views/ThankYouRegister";
import SponsorSingle from "views/SponsorSingle";
import Attend from "views/Attend";
import Venue from "views/Venue";
import DataProvider from "./DataContainer";

ReactDOM.render(
  <DataProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Home {...props} />} />
        <Route path="/sponsor" exact render={(props) => <Sponsor {...props} />} />
        <Route path="/sponsor/:type" exact render={(props) => <Sponsor {...props} />} />
        <Route path="/speakers" exact render={(props) => <Speaker {...props} />} />
        <Route path="/sponsors/:sponsor" exact render={(props) => <SponsorSingle {...props} />} />
        <Route path="/register" exact render={(props) => <RegisterPage {...props} />} />
        <Route path="/attend" exact render={(props) => <Attend {...props} />} />
        <Route
          path="/thank-you-yes"
          exact
          render={(props) => <ThankYou {...props} answer="YES" />}
        />
        <Route path="/thank-you-no" exact render={(props) => <ThankYou {...props} answer="NO" />} />
        <Route
          path="/thank-you/:id"
          exact
          render={(props) => <ThankYouRegister {...props} answer="" />}
        />
        <Route path="/privacy-policy" exact render={(props) => <Privacy {...props} />} />

        <Route path="/venue-travel" exact render={(props) => <Venue {...props} />} />
      </Switch>
    </BrowserRouter>
  </DataProvider>,
  document.getElementById("root")
);
