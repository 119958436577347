import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Register from "components/Register";
import Footer from "components/Footer.js";
// import Sponsor from "components/Sponsor.js";
// import { sponsorsPage } from "./content";

function RegisterPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="REGISTER" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type="REGISTER" />
      </div>

      {/* <Sponsor sponsors={sponsorsPage} /> */}
      <Footer />
    </>
  );
}

export default RegisterPage;
