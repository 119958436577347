import React from "react";
import { Container, Row, Col } from "reactstrap";
import CountUp from "react-countup";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#082a6c",
        }}
        className="py-4"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            {content.map((c, i) => (
              <Col lg={3} xs={6} key={i} className="text-center pt-2 text-white">
                <h1
                  className="text-700 d-inline mt-0"
                  style={{ fontSize: size ? "3rem" : "2rem" }}
                >
                  <CountUp end={c.value} duration={3} />
                  {c.plus && "+"}
                  {c.percent && "%"}
                </h1>
                <h4
                  className="text-400 mt-0"
                  style={{ fontSize: size ? "1rem" : "1rem" }}
                >
                  {c.title}
                </h4>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;

const content = [
  { title: "ATTENDEES", value: 150, image: 32, plus: true },
  { title: "EXPERT SPEAKERS", value: 20, image: 33, plus: true },
  { title: "LIVE SESSIONS", value: 15, image: 34, plus: true },
  { title: "ATTENDEES C-SUITE, VP OR DIRECTOR LEVEL", value: 70, image: 34, percent: true },
];
