import React from 'react';

import { Container, Row, Col } from 'reactstrap';

// import Slider from 'react-slick';

function News({ news }) {
  // const settings = {
  //   dots: true,
  //   autoplay: true,
  //   arrows: false,
  //   autoplaySpeed: 8000,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   className: 'p-10',
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="overlay2 d-none d-xl-block d-lg-block " />
        <div className="overlayMedium d-none d-xl-none d-lg-none d-md-block" />
        <div className="overlayMobile d-xl-none d-lg-none d-md-none d-sm-block d-xs-block" />
        <Container>
          <Row>
            <Col xs={12}>
              <div className="title">
                <h1 className="text-center section-title text-main text-uppercase">
                  <b>A GLIMPSE FROM THE PAST EVENT</b>
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            {images.map((n, index) => (
              <Col lg={4} xs={6} className={'ml-auto mr-auto my-2'}>
                <img
                  src={require(`assets/pastevent/${n.image}`)}
                  width="100%"
                  alt="event"
                  className="px-1"
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default News;

const images = [
  { image: '1.jpeg' },
  { image: '2.jpeg' },
  { image: '3.jpeg' },
  { image: '4.jpeg' },
  { image: '5.jpeg' },
  { image: '6.jpeg' },
  { image: '7.jpeg' },
  { image: '8.jpeg' },
  { image: '9.jpeg' },
  { image: '10.jpeg' },
  { image: '11.jpeg' },
  { image: '12.jpeg' },
  { image: '13.jpeg' },
  { image: '14.jpeg' },
  { image: '15.jpeg' },
  { image: '16.jpeg' },
  { image: '17.jpeg' },
  { image: '18.jpeg' },
  { image: '19.jpeg' },
  { image: '20.jpeg' },
  { image: '21.jpeg' },
  { image: '22.jpeg' },
  { image: '23.jpeg' },
  { image: '24.jpeg' },
  { image: '25.jpeg' },
  { image: '26.jpeg' },
  { image: '27.jpeg' },
];
