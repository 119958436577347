import React from "react";

import { Container, Row, Col } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

function WhoAttend() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#082a6c",
        }}
        className="section section-with-space pb-3"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto " md={12}>
              <h1 className=" mb-4 text-400 text-center text-white">
                <b>WHO SHOULD SPONSOR</b>
              </h1>
              <br />
            </Col>
            <Col lg={12}>
              <Container fluid>
                <Row className="justify-content-center pt-3">
                  {content2.map((data, index) => (
                    <Col lg={4} key={index} className="my-4">
                      <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                        <div
                          className="p-2 text-center"
                          style={{
                            background:
                              "linear-gradient(142deg, rgba(32,120,183,1) 0%, rgba(51,180,171,1) 100%)",
                            borderRadius: "10px",
                            minHeight: "60px",
                            border: "1px solid black",
                          }}
                        >
                          <h2 className="text-700 text-white text-uppercase m-0">{data.title}</h2>
                        </div>
                        <h3 className="text-400 text-white pt-3">{data.desc}</h3>
                        {/* <h3 className="text-700 pt-3">
                            <span className="text-success">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>{" "}
                            {data.title}
                          </h3>
                          <h3 className="text-4 pt-3">{data.desc}</h3> */}
                      </ScrollAnimation>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "#082a6c",
          backgroundImage:
            "url(" +
            require("assets/images/corporate-businessman-giving-presentation-large-audience.png") +
            ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="pb-5"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={5} className="text-left pt-2">
              <h1 className="  text-700 pt-5 text-white">Top 2024 Trends in Healthcare</h1>
              {industries.map((c, i) => (
                <h3 className="text-400 mt-0 text-capitalize text-white" key={i}>
                  <i className="fa fa-caret-right text-g" aria-hidden="true"></i>
                  {c}
                </h3>
              ))}
            </Col>

            <Col lg={5} className="text-left pt-2">
              <h1 className="text-700 pt-5 text-white">Attendees’ Profiles</h1>
              {categories.map((c, i) => (
                <h3 className="text-400 mt-0 text-capitalize text-white" key={i}>
                  <i className="fa fa-caret-right text-g" aria-hidden="true"></i>
                  {c}
                </h3>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content2 = [
  {
    title: "Leading Healthcare Technology Companies",
  },
  {
    title: "Healthcare Supplies Companies",
  },
  {
    title: "Sustainable Healthcare Supplies Manufacturers",
  },
];
const categories = [
  "C-level Executives from Leading Healthcare Organizations",
  "Heads of Unified Business Services",
  "Healthcare Regulators & Policy Makers",
  "Government Officials from the Healthcare Authorities",
  "Heads of Procurement",
  "Heads of IT & Digital Health Transformation",
  "General Managers of Healthcare Facilities",
  "Doctor of Medicine",
  "Directors of Health Informatics",
  "Healthcare Futurists & Medical Innovation Experts",
  "Directors of Strategy",
  "Telehealth and Remote Care Experts",
  "Patient Experience and Engagement Directors",
  "Sustainability Officers in Healthcare",
  "Researchers and academia",
];

const industries = [
  "Telemedicine",
  "Health Tourism",
  "AI & Data Analytics",
  "3D Printing of Prosthetics",
  "Wearable Tech",
  "IoMT (Internet of Medical Things)",
  "Robotics and Automation",
  "EHR (E-Health Records)",
  "Quantum Computing (Deep Mind)",
];

export default WhoAttend;
