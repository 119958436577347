import React from "react";

import { Container, Row, Col, Card, CardImg } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

function WhyAttend() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space py-0 pb-3"
        data-parallax={true}
      >
        <Container className="py-4">
          <Row>
            <Col lg={12}>
              <h1 className="section-title text-left p-2 text-dark mb-5">
                <b>WHY SPONSOR?</b>
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-start">
            {content3.map((data, index) => (
              <Col lg={4} xs={6} key={index} className="py-2">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                  <Card
                    className="rounded-0"
                    style={{
                      minHeight: "300px",
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                  >
                    <CardImg
                      src={require(`assets/images/icons/${data.image}`)}
                      className="mx-auto rounded-0"
                    />
                    <h2 className="text-700 text-left text-dark mt-2">{data.title}</h2>
                    <h3 className="text-400 text-left text-dark">{data.desc}</h3>
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content3 = [
  {
    title: `Maximize brand exposure`,
    desc: `Gain extensive visibility and reach a targeted audience of key decision-makers, industry professionals, and potential clients.`,
    image: "target.jpeg",
  },
  {
    title: `Showcase your solutions`,
    desc: `Demonstrate your company's cutting-edge products, services, and technologies to a highly engaged audience, generating leads and fostering business opportunities.`,
    image: "marketing.jpeg",
  },
  {
    title: `Network with industry leaders`,
    desc: `Connect with top executives, thought leaders, and influencers in the digital health ecosystem, building strategic partnerships and collaborations.`,
    image: "matchmaking.jpeg",
  },
  {
    title: `Thought leadership opportunities`,
    desc: `Establish your company as an industry authority by participating in panel discussions, delivering keynote speeches, and sharing your expertise on digital health trends and innovations.`,
    image: "partnership.jpeg",
  },
  {
    title: `Shape the future of healthcare`,
    desc: `Contribute to the advancement of digital health by supporting and participating in discussions, collaborations, and initiatives that drive positive change in the industry.`,
    image: "brand.jpeg",
  },
];

export default WhyAttend;
