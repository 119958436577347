import React, { useEffect } from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
// import Sponsor from "components/Sponsor.js";
// import Testimonials from "components/Testimonials.js";

// import { sponsors } from "./content";
import { Container, Row, Col } from "reactstrap";

function ThankYou({
  answer,
  match: {
    params: { id },
  },
}) {
  const brouchureLink = '/assets/Future of Healthcare - 4th Edition - Khaleej Times Events.pdf';

  useEffect(() => {
    if (id === "BROCHURE") {
      setTimeout(() => {
        const link = document.createElement('a');
        link.href = brouchureLink;
        link.download = brouchureLink.split('/').pop(); // Use the filename from the URL
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.open(brouchureLink, '_blank');
      }, 3333);
    }
  }, [id]);
  return (
    <>
      <Navbar />
      <ProfilePageHeader title={`THANK YOU ${answer}`} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center p-5 m-5">
              <h1>
                {id === "REPORT" && <></>}
                {id === "BROCHURE" && (
                  <>
                    Thank you for your interest in the Middle East Digital Health Forum. 
                    The event brochure will download automatically. If the download doesn't start, please contact us at
                    <a href="mailto:salma@khaleejtimes.com"> salma@khaleejtimes.com</a>
                  </>
                )}
                {id === "REGISTER" && (
                  <>
                    Thank you for your interest in Middle East Digital Health
                    Forum. We’ll be in touch with you for further details about
                    the event.
                  </>
                )}
                {id === "SPONSOR" && (
                  <>
                    Thank you for your interest. A member of our team will be in
                    touch with you. Please feel free to reach out to us at +971
                    4 4050817 for any further queries.
                  </>
                )}
              </h1>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
}

export default ThankYou;
