import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Register from "components/Register";

import Footer from "components/Footer.js";
import { Redirect } from "react-router";
// import Sponsor from "components/SponsorPage.js";
// import { sponsorsPage } from "./content";

function Overview({ match }) {
  const { type } = match.params;

  if (!type || !["download-brochure", "sponsorship-enquiry"].includes(type)) {
    return <Redirect to="/sponsor/why-sponsor" />;
  }
  let title = "";
  if (type === "download-brochure") {
    title = "DOWNLOAD BROCHURE";
  }
  if (type === "sponsorship-enquiry") {
    title = "FIND OUT MORE ABOUT SPONSORSHIP OPPORTUNITIES";
  }

  return (
    <>
      <Navbar />
      <ProfilePageHeader title={title} bgColor="#fff" />
      {/* <Sponsor sponsors={sponsorsPage} /> */}
      <div className="section profile-content" style={{ padding: 0 }}>
        {type === "download-brochure" && (
          <Register
            type="BROCHURE"
            title=""
            successMsg={`Thank you for your request. An email with the event brochure has been sent to the email address provided. For more information, you can contact us on +971 56 6292801.`}
          />
        )}
        {type === "sponsorship-enquiry" && <Register type="SPONSOR" title="" />}
      </div>
      <Footer />
    </>
  );
}

export default Overview;
