import React from "react";
import Navbar from "components/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import Footer from "components/Footer.js";
import TextGrid from "components/TextGrid.js";
import Agenda from "components/Agenda.js";
// import WhoAttend from "components/WhoAttend.js";
import KeyTopics from "components/KeyTopics.js";
import SpeakerCard from "components/SpeakerCard.js";
import SpeakerCardNew from "components/SpeakerCardNew.js";

import Sponsor from "components/Sponsor.js";
import { pastspeakers, speakers, sponsorsPage } from "./content";
import WhyAttend from "components/WhyAttend";
import WhoSponsor from "components/WhoSponsor";
// import WhySponsor from "components/WhySponsor";
import Stats from "components/Stats";
import Photos from "components/Photos";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <div id="outer-container">
        <Navbar home={true} />
        <section id="home" />
        <LandingPageHeader />
        <Stats />
        <main id="page-wrap">
          <section id="about" />
          <TextGrid />
          <KeyTopics />
          {/* <WhoAttend/> */}
          <div className="main">
            <section id="speakers" />
            <SpeakerCardNew />
            <SpeakerCard speakers={speakers} pastspeakers={pastspeakers} />
          </div>

          <section id="agenda" />
          <Agenda />
          <section id="sponsor" />
          <WhyAttend />
          <WhoSponsor />
          <Sponsor sponsors={sponsorsPage} />
          <section id="2022Glimpses" />
          <Photos />
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
