import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Venue from "components/Venue";

import Footer from "components/Footer.js";
import Sponsor from "components/Sponsor.js";

import { sponsorsPage } from "./content";

function Overview() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader
        title="How to get to the Middle East Digital Health Forum"
        bgColor="#fff"
      />
      <Venue />

      <Sponsor sponsors={sponsorsPage} />
      <Footer />
    </>
  );
}

export default Overview;
