import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";
// const size =
//   document.body.clientWidth >= 1024 ||
//   document.documentElement.clientWidth > 1024
//     ? true
//     : false;

const content = [
  { title: "Digital Health Landscape of the UAE", image: "4.png" },
  { title: "Telemedicine and Remote Healthcare", image: "2.png" },
  { title: "Healthcare Megatrends - 2030", image: "3.png" },
  { title: "Data Analytics and AI in Healthcare", image: "9.png" },
  { title: "Cybersecurity and Privacy in Digital Health", image: "5.png" },
  { title: "Building a Digitally Advanced Healthcare Ecosystem", image: "6.png" },
  { title: "Patient-Centric Care and Digital Transformation", image: "7.png" },
  { title: "PPPs in Healthcare – Way forward?", image: "8.png" },
  { title: "Role of Digital Health to Achieve a Connected Patient", image: "1.png" },
  { title: "Experience Disruptive Startups in the Digital Health Space", image: "10.png" },
  {
    title: "Shaping the Future of Digital Health: Collaborations, Policies and Regulations",
    image: "1.png",
  },
];
const size =
  document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
function KeyNotes() {
  return (
    <>
      <div className="section py-5" style={{ background: "#082a6c" }}>
        <Container>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto " md={12}>
              <h1 className="section-title mb-4 text-700 text-white text-center">
                <b>EVENT KEY HIGHLIGHTS</b>
              </h1>
              <br />
            </Col>
            {content.map((c, i) => (
              <Col lg={4} xs={11} className="px-1 py-4">
                <Card
                  style={{
                    background: "rgba(0,0,0,0)",
                    height: size ? "100px" : "none",
                    boxShadow: "none",
                  }}
                  className="px-3"
                >
                  <div className="d-flex">
                    <div
                      className="px-2 align-self-center rounded-circle mr-2"
                      style={{
                        minWidth: 100,
                        height: 100,
                        background: i % 2 === 0 ? "#082a6c" : "#2fbaca",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "5px solid #ffffff",
                      }}
                    >
                      <img
                        src={require(`assets/images/icons/${c.image}`)}
                        style={{ width: "60px", height: "60px" }}
                        alt="about"
                        className=""
                      />
                    </div>
                    <div
                      className="d-flex align-items-center w-100"
                      style={{
                        boxShadow: "0",
                        // background: "linear-gradient(270deg, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 80%)",
                        // // borderRadius: "30px",
                      }}
                    >
                      <h3 className="text-400 py-2 pl-3 text-white align-self-center">{c.title}</h3>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
